import { useCallback, useMemo } from 'react';

import * as authApi from 'client/services/auth';
import { User } from 'client/services/user/types/user.type';

import useAuthContext from './useAuthContext';
import useNotification from './useNotification';

type LogoutType = {
  logout: () => void;
};

export const useLogout = (): LogoutType => {
  const { setUser } = useAuthContext();
  const { setError } = useNotification();

  const logout = useCallback(async () => {
    try {
      await authApi.logout();
      setUser({} as User);
    } catch (error) {
      setError(error);
    }
  }, [setUser, setError]);

  return useMemo(
    () => ({
      logout,
    }),
    [logout],
  );
};
