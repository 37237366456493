import axios from 'axios';

const instance = axios.create({
  baseURL:
    (typeof window !== 'undefined' && window.env.API_BASE_URL) ||
    process.env.API_BASE_URL ||
    API_BASE_URL,
  withCredentials: true,
});

instance.interceptors.response.use((response) => response.data);

export default instance;
