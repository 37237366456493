import { RouteProps } from 'react-router';

import { RoutesEnum } from 'client/enums/routes.enum';
import { RolesEnum } from 'client/services/roles/enums/roles.enum';

import { RoleGroupsEnum } from '../components/Permission/enums/role-groups.enum';

export type RouteItem = {
  routeProps: RouteProps;
  page: string;
  screen?: string;
  allowedForRoles?: (RolesEnum | RoleGroupsEnum)[];
};

export const AuthRoutes = [
  { routeProps: { path: RoutesEnum.Dashboard }, page: 'Dashboard' },
  { routeProps: { path: RoutesEnum.Settings }, page: 'Settings' },
  { routeProps: { path: RoutesEnum.Orders, exact: true }, page: 'Orders' },
  {
    routeProps: { path: RoutesEnum.OrdersDetails },
    page: 'Orders',
    screen: 'OrdersAdminDetails',
  },
  {
    routeProps: { path: RoutesEnum.Admin, exact: true },
    page: 'Admins',
    allowedForRoles: [RolesEnum.AdminTeamSuperAdmin],
  },
  {
    routeProps: { path: RoutesEnum.AdminDetails },
    page: 'Admins',
    screen: 'AdminDetails',
    allowedForRoles: [RolesEnum.AdminTeamSuperAdmin],
  },
  {
    routeProps: { path: RoutesEnum.Users, exact: true },
    page: 'Users',
    allowedForRoles: [RolesEnum.ClientTeamAdmin],
  },
  {
    routeProps: { path: RoutesEnum.UserDetails },
    page: 'Users',
    screen: 'UserDetails',
  },
  { routeProps: { path: RoutesEnum.Orders }, page: 'Orders' },
  { routeProps: { path: RoutesEnum.Profile }, page: 'Profile' },
  { routeProps: { path: RoutesEnum.Sims, exact: true }, page: 'SimInventory' },
  {
    routeProps: { path: RoutesEnum.SimsDetails },
    page: 'SimInventory',
    screen: 'SimInventoryDetails',
  },
  { routeProps: { path: RoutesEnum.Reports }, page: 'Reports' },
  {
    routeProps: {
      path: RoutesEnum.NetworkAccessProfiles,
      exact: true,
      allowedForRoles: [RolesEnum.AdminTeamSuperAdmin],
    },
    page: 'NetworkAccessProfiles',
  },
  {
    routeProps: { path: RoutesEnum.NetworkAccessProfileDetails },
    page: 'NetworkAccessProfiles',
    screen: 'NetworkAccessProfileDetails',
    allowedForRoles: [RolesEnum.AdminTeamSuperAdmin],
  },
  {
    routeProps: { path: RoutesEnum.Clients, exact: true },
    page: 'Clients',
    allowedForRoles: [RoleGroupsEnum.Admin],
  },
  {
    routeProps: { path: RoutesEnum.ClientDetails },
    page: 'Clients',
    screen: 'ClientDetails',
    allowedForRoles: [RoleGroupsEnum.Admin],
  },
  {
    routeProps: { path: RoutesEnum.Fleets, exact: true },
    page: 'Fleets',
    allowedForRoles: [RoleGroupsEnum.Client],
  },
  {
    routeProps: { path: RoutesEnum.FleetDetails },
    page: 'Fleets',
    screen: 'FleetEdit',
    allowedForRoles: [RoleGroupsEnum.Client],
  },
  {
    routeProps: { path: RoutesEnum.Billing },
    page: 'Billing',
    allowedForRoles: [RolesEnum.ClientTeamAdmin],
  },
] as RouteItem[];

// This route should be last in the list.
// That's why we push it explıcıtly to avoid inattentiveness
AuthRoutes.push({ routeProps: { path: RoutesEnum.Main }, page: 'Dashboard' });

export const UnAuthRoutes = [
  { routeProps: { path: RoutesEnum.Login }, page: 'Login' },
  { routeProps: { path: RoutesEnum.Register }, page: 'Register' },
  { routeProps: { path: RoutesEnum.ForgotPassword }, page: 'ForgotPassword' },
] as RouteItem[];

export const AuthRouteProps = AuthRoutes.map(
  (item) => item.routeProps.path,
) as string[];
export const UnAuthRouteProps = UnAuthRoutes.map(
  (item) => item.routeProps.path,
) as string[];
