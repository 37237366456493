import { MFAProps } from 'client/hooks/useMFA';
import instance from 'client/utils/api-client';

import { User } from '../user/types/user.type';
import { AuthPhoneVerifyType } from './types/auth-phone-verify.type';
import { AuthResponseType } from './types/auth-response.type';
import { TokenType } from './types/auth-token.type';
import { ConfirmPasswordCallType } from './types/confirm-password-call.type';
import { ForgotPasswordCallType } from './types/forgot-password-call.type';
import { LoginCallType } from './types/login-call.type';
import { RegisterCallType } from './types/register-call.type';
import { ResendConfirmationCodeType } from './types/resend-confirmation-code.type';

export const login = (
  params: LoginCallType,
): Promise<AuthResponseType<TokenType>> => {
  return instance.post('auth/login', params);
};

export const sendCode = (): Promise<AuthResponseType> => {
  return instance.post('auth/phone/verify');
};

export const verify = (
  params: AuthPhoneVerifyType,
): Promise<AuthResponseType> => {
  return instance.post('auth/phone/verify', params);
};

export const register = (params: RegisterCallType): Promise<User> => {
  return instance.post('auth/register', params);
};

export const toggle = (params: MFAProps): Promise<AuthResponseType> => {
  return instance.post('auth/mfa/toggle', params);
};

export const confirm = (params: MFAProps): Promise<AuthResponseType> => {
  return instance.post('auth/mfa/confirm', params);
};

export const forgotPassword = (
  params: ForgotPasswordCallType,
): Promise<AuthResponseType> => {
  return instance.post('auth/password/forgot', params);
};

export const confirmPassword = (
  params: ConfirmPasswordCallType,
): Promise<AuthResponseType> => {
  return instance.post('auth/password/confirm', params);
};

export const resendConfirmationCode = (params: ResendConfirmationCodeType) => {
  return instance.post('auth/confirmation/resend', params);
};

export const logout = (): Promise<void> => {
  return instance.post('auth/logout');
};
