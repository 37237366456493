import { useCallback, useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import * as api from 'client/services/payment';
import { CreateOrderParams } from 'client/services/payment/types/create-order-params.type';

type UsePaymentType = {
  loading: boolean;
  createPayment: (params: CreateOrderParams) => Promise<string>;
  proceedPayment: (
    secret: string | undefined,
    methodId?: string | undefined,
  ) => Promise<void>;
};

export const usePayment = (): UsePaymentType => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);

  const createPayment = useCallback((params: CreateOrderParams) => {
    setLoading(true);
    return api.order.create(params).finally(() => setLoading(false));
  }, []);

  const proceedPayment = useCallback(
    async (secret: string | undefined, methodId: string | undefined) => {
      if (!stripe || !elements || !secret)
        return Promise.reject('Payment Error');

      setLoading(true);

      try {
        const data = await stripe.confirmCardPayment(secret, {
          payment_method: methodId
            ? methodId
            : {
                card: elements.getElement(CardElement)!,
              },
        });

        if (data.error && data.error.message)
          return Promise.reject(data.error.message);
        else if (
          !data.paymentIntent ||
          data.paymentIntent.status !== 'succeeded'
        )
          return Promise.reject('Payment Error');
      } catch (error) {
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements],
  );

  return {
    loading,
    createPayment,
    proceedPayment,
  };
};

export default usePayment;
