import instance from 'client/utils/api-client';

import { ResetUserType } from './types/reset-user.type';
import { User } from './types/user.type';

export const getUsers = (params?: Partial<User>): Promise<User[]> => {
  return instance.get('/users', params && { params });
};

export const getUser = (id: string): Promise<User> => {
  return instance.get(`/users/${id}`);
};

export const getCurrentUser = (): Promise<User> => {
  return instance.get('/users/profile');
};

export const createUser = (params: Partial<User>): Promise<User> => {
  return instance.post('/users', params);
};

export const updateUser = (
  id: string,
  params: Partial<User> | FormData,
): Promise<User> => {
  return instance.patch(`/users/${id}`, params);
};

export const resetUser = (params: ResetUserType) => {
  return instance.post('/users/reset', params);
};
