import React, { useMemo, useRef } from 'react';

import useAuthContext from '../hooks/useAuthContext';
import instance from './api-client';

export const WithAxios = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { user } = useAuthContext();
  const clientProviderInterceptorRef = useRef(0);

  useMemo(() => {
    instance.interceptors.request.eject(clientProviderInterceptorRef.current);

    clientProviderInterceptorRef.current = instance.interceptors.request.use(
      (config) => {
        if (config && config.headers && user.client?.providerAccountId)
          config.headers['Provider-Id'] = user.client.providerAccountId;

        return config;
      },
    );
  }, [user]);

  return <>{children}</>;
};
