import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as usersApi from 'client/services/user';
import { User } from 'client/services/user/types/user.type';

import useNotification from './useNotification';

interface AuthContextType {
  loading: boolean;
  user: User;
  setUserContext: () => Promise<void>;
  setUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const { setError } = useNotification();

  useEffect(() => {
    usersApi
      .getCurrentUser()
      .then((user) => {
        return setUser(user);
      })
      .finally(() => setLoadingInitial(false));
  }, []);

  const setUserContext = useCallback(async () => {
    try {
      setUser(await usersApi.getCurrentUser());
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setUser, setError, setLoading]);

  const memoizedValue = useMemo(
    () => ({
      loading,
      user,
      setUserContext,
      setUser,
    }),
    [loading, user, setUserContext, setUser],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = function (): AuthContextType {
  const authContext = useContext(AuthContext);
  if (authContext === undefined)
    throw new Error('useAuth must be used within a AuthProvider');

  return authContext;
};

export default useAuthContext;
