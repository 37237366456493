import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { HttpError } from 'http-errors';

enum NotificationTypes {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
}

export interface Notification {
  message: string;
  type?: NotificationTypes;
}

interface NotificationContextType {
  notification: Notification;
  errorCode: number;
  resetErrorCode: (errorCode: number) => void;
  clearNotification: (type: NotificationTypes | undefined) => void;
  setNotification: (notification: Notification) => void;
  setError: (message: string) => void;
  setSuccess: (message: string) => void;
  setInfo: (message: string) => void;
  setWarning: (message: string) => void;
}

const EmptyNotification = { message: '' };

const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType,
);

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [notification, setNotification] =
    useState<Notification>(EmptyNotification);

  const clearNotification = useCallback((type) => {
    setNotification({ ...EmptyNotification, type });
  }, []);

  const [errorCode, resetErrorCode] = useState(0);

  const setError = useCallback((error: HttpError | string) => {
    typeof error === 'object' && resetErrorCode(error.response.status);
    const message =
      typeof error === 'object'
        ? error.response?.data.message || error.message
        : error;
    setNotification({ message, type: NotificationTypes.Error });
  }, []);

  const setSuccess = useCallback((message: string) => {
    setNotification({ message, type: NotificationTypes.Success });
  }, []);

  const setInfo = useCallback((message: string) => {
    setNotification({ message, type: NotificationTypes.Info });
  }, []);

  const setWarning = useCallback((message: string) => {
    setNotification({ message, type: NotificationTypes.Warning });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      errorCode,
      resetErrorCode,
      notification,
      setNotification,
      clearNotification,
      setError,
      setSuccess,
      setInfo,
      setWarning,
    }),
    [
      errorCode,
      resetErrorCode,
      notification,
      setNotification,
      clearNotification,
      setError,
      setSuccess,
      setInfo,
      setWarning,
    ],
  );

  return (
    <NotificationContext.Provider value={memoizedValue}>
      {children}
    </NotificationContext.Provider>
  );
}

export default function useNotification(): NotificationContextType {
  const notificationContext = useContext(NotificationContext);
  if (notificationContext === undefined)
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );

  return notificationContext;
}
