import React, { useState, useRef, useEffect } from 'react';

import { Box } from '@mui/material';

import { FormInputTypeEnum } from 'client/enums/form.enum';
import { noValue } from 'client/utils/noValue';

import { useStateWithDep } from '../../hooks/useStateWithDep';
import {
  RecordEditableWrapper,
  RecordEditableLabel,
  RecordEditableValue,
  RecordEditableText,
  RecordEditableInput,
  RecordEditableSelect,
  RecordEditableButton,
  RecordEditableContent,
} from './RecordEditable.styled';

export type RecordEditableProps = {
  label: string;
  name: string;
  type: FormInputTypeEnum;
  value: string;
  fontSize?: number;
  onBlur: (event: React.FocusEvent) => void;
  options?: string[];
  className?: string;
};

export const RecordEditable: React.FC<RecordEditableProps> = ({
  label,
  name,
  fontSize = 12,
  type,
  value: defaultValue,
  onBlur,
  options = [],
  className,
}) => {
  const input = useRef(null);

  const [editable, setEditable] = useState(false);
  const [value, setValue] = useStateWithDep(defaultValue);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focused) (input as any).current.children[0].children[0].focus();
  });

  return (
    <RecordEditableWrapper className={className}>
      <RecordEditableLabel fontSize={fontSize} variant="body2">
        {label}
      </RecordEditableLabel>
      <RecordEditableValue>
        <Box ref={input}>
          {type === FormInputTypeEnum.Input && editable && (
            <RecordEditableInput
              name={name}
              defaultValue={value}
              onChange={(event) => setValue(event.target.value)}
              onBlur={(event) => {
                setEditable(false);
                setFocused(false);
                onBlur(event);
              }}
            />
          )}
          {type === FormInputTypeEnum.Select && editable && (
            <RecordEditableSelect
              name={name}
              defaultValue={value}
              onChange={(event) => setValue(event.target.value)}
              onBlur={(event) => {
                setEditable(false);
                setFocused(false);
                onBlur(event);
              }}
            >
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </RecordEditableSelect>
          )}
        </Box>
        {!editable && (
          <RecordEditableContent>
            <RecordEditableText fontSize={fontSize} variant="body2">
              {value || noValue()}
            </RecordEditableText>
            <RecordEditableButton
              variant="body2"
              onClick={() => {
                setEditable(true);
                setFocused(true);
              }}
            >
              Edit
            </RecordEditableButton>
          </RecordEditableContent>
        )}
      </RecordEditableValue>
    </RecordEditableWrapper>
  );
};

export default RecordEditable;
